import React, {useContext, useRef, useState} from 'react';
import MyInput from "../components/UI/input/MyInput";
import MyButton from "../components/UI/button/MyButton";
import {AuthContext, TokenContext} from "../context";
import {useNavigate} from "react-router-dom";
import {useFetching} from "../hooks/useFetching";
import PostService from "../API/PostService";
import axios from "axios";
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Container, Form, Spinner} from "react-bootstrap";
import {gs_email, gs_token} from "../API/global";

//Вход для админской панели

const Login = () => {
 //   const token = useRef();
 //   const email = useRef();
//    const password = useRef();
    const [state, setState] = useState({email: '' , password: '' });
    let navigate = useNavigate();
    const [okDisabled, setOkDisabled] = useState(false)
    const [loading, setLoading] = useState(false);

    function proc() {
        setLoading(false);
        navigate("/admin")
    }

    function proc2() {
        setOkDisabled(false);
        setLoading(false);
    }

    const login = event => {
        event.preventDefault();
        setLoading(true);
        setOkDisabled(true);
        PostService.loginAdmin(state.email, state.password, proc, proc2);
    }

    function handleChange(event) {
        let a = event.target.value;
        setState({email: a, password: state.password});
    }

    function passwordChange(event) {
        let a = event.target.value;
        setState({email: state.email, password: a});
    }

    return (
        <Container>
            <Form onSubmit={login}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Text className="mb-3">
                        <h3>
                            Введите данные для входа
                        </h3>
                    </Form.Text>
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" placeholder="Введите вашу почту" value={state.email}
                                  onChange={handleChange}/>

                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">

                    <Form.Label>Пароль</Form.Label>
                    <Form.Control type="password" placeholder="Введите пароль" value={state.password}
                                  onChange={passwordChange}/>

                </Form.Group>

                <MyButton variant="primary" disabled={okDisabled} type="submit">
                    {
                        loading
                            ?
                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            : <span>Далее</span>
                    }
                </MyButton>
            </Form>
        </Container>

    );
};

export default Login;
