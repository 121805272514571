import React, {useContext} from 'react';
import {Link, useNavigate} from "react-router-dom";
import MyButton from "../button/MyButton";
import {AuthContext} from "../../../context";
import {Container, Row} from "react-bootstrap";
import CloseButton from 'react-bootstrap/CloseButton';

const Navbar = () => {
    const {isAuth, setIsAuth} = useContext(AuthContext);
    const {step, setStep} = useContext(AuthContext);
    let navigate = useNavigate();
    const logout = () => {
        setIsAuth(false);
        localStorage.removeItem('auth')

        navigate("/login")
    }

    const back = () => {
        setStep(step - 1);
        localStorage.setItem('step', step)
        switch (step) {
            case '1': {
                navigate("/login");
            }
            case '2': {
                navigate("/admin");
            }
        }
    }

    const closeButton = () => {
        window.close();
    }

    return (
        <Row>
            <div> </div>


        </Row>
    )

    /*
                <Container className="mb-3">
                <CloseButton onClick={closeButton}/>
            </Container>


    if (step != '1') {


      } else {
          return (
              <row>
                  <Container className="mb-3">
                      <CloseButton/>
                  </Container>

              </row>
          );
      }*/


    /*
                           <Container className="navbar">
                    <MyButton onClick={back}>
                        Назад
                    </MyButton>
                    <MyButton onClick={logout}>
                        Выйти
                    </MyButton>
                    <div className="navbar__links">
                        <Link to="/about">О сайте</Link>
                        <Link to="/posts">Посты</Link>
                    </div>
                </Container>

           <Container className="navbar">
        <MyButton onClick={logout}>
            Выйти
        </MyButton>
        <div className="navbar__links">
            <Link to="/about">О сайте</Link>
            <Link to="/posts">Посты</Link>
        </div>
    </Container>*/
};

export default Navbar;
