import React from 'react';
import MyButton from "./UI/button/MyButton";
import {useNavigate} from 'react-router-dom';

const PostItem = (props) => {
    const router = useNavigate()

    return (
        <div className="post">
            <div className="post__content">
                <strong>{props.post.id}. {props.post.name}</strong>
                <div>
                    {props.post.description}
                </div>
                <div>
                    {props.post.create_date} {props.post.count} {props.post.price} {props.post.summa}
                </div>
            </div>

        </div>
    );
};


/*
            <div className="post__btns">
                <MyButton onClick={() => router.push(`/posts/${props.post.id}`)}>
                    Открыть
                </MyButton>
                <MyButton onClick={() => props.remove(props.post)}>
                    Удалить
                </MyButton>
            </div>
 */

export default PostItem;
