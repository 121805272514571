import React, {useState} from 'react';
import MyInput from "../UI/input/MyInput";
import MyButton from "../UI/button/MyButton";

const PostForm = ({create}) => {
    const [post, setPost] = useState({name: '', description: ''})


    const addNewPost = (e) => {
        e.preventDefault()
        const newPost = {
            ...post, id: Date.now()
        }
        create(newPost)
        setPost({name: '', description: ''})
    }

    return (
        <form>
            {/*Управляемый компонент*/}
            <MyInput
                value={post.name}
                onChange={e => setPost({...post, name: e.target.value})}
                type="text"
                placeholder="Название"
            />
            {/*Неуправляемый\Неконтролируемый компонент*/}
            <MyInput
                value={post.description}
                onChange={e => setPost({...post, description: e.target.value})}
                type="text"
                placeholder="Описание"
            />
            <MyButton onClick={addNewPost}>Создать запись</MyButton>
        </form>
    );
};

export default PostForm;
