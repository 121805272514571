
const gs_root= '';
//const gs_root= 'https://pay.sanasoftco.kz';

let gs_email='';
let gs_token='';
let gs_tokenbilet='';
let gs_selectproduct='1';
let gs_idpay='';
let gs_tokenadmin='';
let gs_invoice_id='';

global.gs_email = gs_email;
global.gs_token = gs_token;
global.gs_tokenbilet = gs_tokenbilet;
global.gs_selectproduct = gs_selectproduct;
global.gs_idpay = gs_idpay;
global.gs_tokenadmin = gs_tokenadmin;
global.gs_invoice_id = gs_invoice_id;