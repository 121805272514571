import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import MyInput from "../components/UI/input/MyInput";
import MyButton from "../components/UI/button/MyButton";
import Loader from "../components/UI/Loader/Loader";
import {AuthContext, TokenContext} from "../context";
import {useNavigate} from "react-router-dom";
import {useFetching} from "../hooks/useFetching";
import PostService from "../API/PostService";
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Container, Form, Row, Spinner} from "react-bootstrap";
import {halyk, gs_email, gs_token, gs_tokenbilet, gs_selectproduct} from '../API/global.js'
import validator from "validator";
import {getPageCount} from "../utils/pages";
import Col from 'react-bootstrap/Col';
import Helmet from "react-helmet"
import Footer from "../components/Footer";

//import * as payment from './payment-api.js'


const Product = () => {
        const [validated, setValidated] = useState(false);
        const [state, setState] = useState({email: ''});

        const [message, setMessage] = useState("");

        let navigate = useNavigate();
        const [okDisabled, setOkDisabled] = useState(true);
        const [loading, setLoading] = useState(false);
        const [products, setProducts] = useState([]);

        const [price, setPrice] = useState(0);
        const [summa, setSumma] = useState(0);
        const [count, setCount] = useState(1);

        // gs_selectproduct='1';

        //текущий продукт
        const [selectProduct, setSelectProduct] = useState(global.gs_selectproduct);


        const privateChangeSelect = async (value, products) => {
            let a = value;
            setSelectProduct(a);
            if (a > 0) {

                let obj = products[a - 1];
                console.log(obj);
                setPrice(obj.price);
                setSumma(obj.price * count);
            } else {
                setPrice(0);
                setSumma(0);
            }
        }


        function changeSelect(event) { //При смене продукта
            let a = event.target.value;
            privateChangeSelect(a, products);

        }


        const [fetchPosts, isPostsLoading, postError] = useFetching(async (gs_tokenbilet) => {
            const response = await PostService.getProducts(gs_tokenbilet);
            if (response && response.data) {
                if (response.data.success) {

                    setProducts(response.data.data);
                    if (response.data.data.length > 0) {
                        console.log('gs_selectproduct ' + global.gs_selectproduct);
                        privateChangeSelect(global.gs_selectproduct, response.data.data);
                    } else {
                        privateChangeSelect(0, response.data.data);
                    }

                }
            } else {
            }
        });

        useEffect(() => { //Загружаем список продуктов
            fetchPosts(global.gs_tokenbilet, isPostsLoading, postError);
            setSelectProduct(global.gs_selectproduct);
            if (gs_selectproduct > 0) {
                setOkDisabled(false);
            } else {
                setOkDisabled(true);
            }

        }, []) // обязательно пустой массив, иначе будет бесконечный цикл


        const login = async (event) => {

        }

        function proc() {
            setLoading(false);
            //     token.current = response.data.token;
            //    console.log('token ' + token.current);
            gs_email = state.email;
            global.gs_email = state.email;
            //      gs_token = response.data.token;
            localStorage.setItem('step', '1');
            navigate("/password")
        }

        function proc2() {
            setOkDisabled(false);
            setLoading(false);
        }

        //Формируем объект для открытия окна оплаты
        let createPaymentObject = function (res, email, summa) {
            let auth = res.epay;

            var zeroLength = 8;

            let num = res.id.padStart(zeroLength, '0');
            console.log(num);
            let paymentObject = {
                invoiceId: num,
                backLink: "https://pay.sanasoftco.kz/Success?id=" + res.id,
                failureBackLink: "https://pay.sanasoftco.kz/Failure?id=" + res.id,
                postLink: "https://pay.sanasoftco.kz/api/paypost/",
                failurePostLink: "https://pay.sanasoftco.kz/api/paypostfail/",
                language: "RU",
                description: "Оплата в интернет магазине",
                accountId: res.dataext.id_token,
                terminal: res.dataext.epay_terminal,
                amount: summa,
                data: "",   //"{\"statement\":{\"name\":\"Arman      Ali\",\"invoiceID\":\"80000016\"}}",
                currency: "KZT",
                phone: res.dataext.phone,
                email: email,
                cardSave: true //Параметр должен передаваться как Boolean
            };
            paymentObject.auth = auth;
            return paymentObject;
        };

        //Обработка запроса в случае успеха. Переход на страницу оплаты
        function procSentPay(data) {
          //  console.log('Окно покупки');
            let datar = createPaymentObject(data, global.gs_email, summa);
            //          console.log(datar);
          //  console.log(datar);
            window['procEpay'](datar);
        }

        //Нажатие далее. Октрываем окно платежа
        const processClick = async (event) => {
            event.preventDefault();


            if ((summa > 0) && (selectProduct > 0)) {
                setValidated(false);
                let obj = products[selectProduct - 1];
                let res = PostService.sentPay(global.gs_email, global.gs_tokenbilet, obj.id, obj.name, obj.description, price, count, summa, procSentPay);
                if (res) {

                }
            } else {
                setValidated(true);
            }

        }

        //Изменяем количаство
        function changeCount(event) {
            console.log(event.target.value);
            let a = event.target.value;
            setCount(a);
            setSumma(a * price);
            //    console.log(summa);
            // setState({email: a});
        }


        return (
            <Container>

                <Form noValidate validated={validated} onSubmit={processClick}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Text className="mb-3">
                            <h3>
                                Выберите продукт и количество
                            </h3>
                        </Form.Text>


                        <Row className="mb-3">

                            <Form.Group xs={6} as={Col} controlId="formGridCity">
                                <Form.Label>Продукт</Form.Label>
                                <Form.Select onChange={changeSelect} value={selectProduct} aria-label="Выберите продукт">
                                    {
                                        products.map((option, index) => {


                                            return (<option key={index} value={option.id}>{option.name}</option>)


                                        })
                                    }
                                </Form.Select>
                            </Form.Group>

                            <Form.Group xs={2} as={Col} controlId="formPrice">
                                <Form.Label>Цена</Form.Label>
                                <Form.Control value={price} readOnly type="number"/>
                            </Form.Group>

                            <Form.Group xs={2} as={Col} controlId="formCount">
                                <Form.Label>Кол-во</Form.Label>
                                <Form.Control min="1" value={count} onChange={changeCount} type="number"/>
                                <Form.Control.Feedback type="invalid">
                                    Пожалуйста, введите количество больше нуля
                                </Form.Control.Feedback>
                            </Form.Group>


                            <Form.Group xs={2} as={Col} controlId="formSumma">
                                <Form.Label>Сумма</Form.Label>
                                <Form.Control value={summa} readOnly type="number"/>
                            </Form.Group>
                        </Row>

                        <span
                            style={{
                                fontWeight: "bold",
                                color: "red"
                            }}
                        >
        {message}
      </span>

                    </Form.Group>

                    <MyButton onClick={processClick} variant="primary" disabled={okDisabled} type="submit">
                        {
                            loading
                                ?
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                : <span>Далее</span>
                        }
                    </MyButton>

                </Form>
                <Footer/>
            </Container>

        );
    }
;
/*
  <Form.Text className="text-muted">
                            Вам будет отправлен код по E-mail
                        </Form.Text>



                if (gs_selectproduct == index + 1) {  //Выбор продукта по умолчанию
                                                return (
                                                    <option selected key={index} value={option.id}>{option.name}</option>)
                                            } else {
                                                return (<option key={index} value={option.id}>{option.name}</option>)
                                            }

*/
export default Product;


