// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MyInput_myInput__XH6g9 {\n    width: 100%;\n    padding: 5px 15px;\n    margin: 5px 0;\n    border: 1px solid teal;\n}\n\n.MyInput_myPasswordInput__YAZs7 {\n    display: inline-block;\n    width: 20px;\n    padding: 2px 2px;\n    margin: 1px;\n    border: 1px solid teal;\n}", "",{"version":3,"sources":["webpack://./src/components/UI/input/MyInput.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,iBAAiB;IACjB,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,qBAAqB;IACrB,WAAW;IACX,gBAAgB;IAChB,WAAW;IACX,sBAAsB;AAC1B","sourcesContent":[".myInput {\n    width: 100%;\n    padding: 5px 15px;\n    margin: 5px 0;\n    border: 1px solid teal;\n}\n\n.myPasswordInput {\n    display: inline-block;\n    width: 20px;\n    padding: 2px 2px;\n    margin: 1px;\n    border: 1px solid teal;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"myInput": "MyInput_myInput__XH6g9",
	"myPasswordInput": "MyInput_myPasswordInput__YAZs7"
};
export default ___CSS_LOADER_EXPORT___;
