import React, {useState} from 'react';
import PostService from "../API/PostService";
import {useSearchParams} from "react-router-dom";
import Footer from "../components/Footer";
import {Container} from "react-bootstrap";

const Failure = () => {
    const [message, setMessage] = useState("");
    const [searchParams] = useSearchParams();
    const id = searchParams.get('id');
    //   const query = new URLSearchParams(this.props.location.search);
    //   const id = query.get('id')
    console.log(id)//123
    global.gs_invoice_id = id;

    let response = PostService.getStatus(global.gs_invoice_id);
    if (response.success) {
        setMessage(response.reason + '; Code: ' + response.reasonCode);
    }
    return (
        <Container>
            <div>
                <h1>
                    Операция закончилась неудачей
                </h1>
                <span>Ошибка: {message}</span>
            </div>
            <Footer/>
        </Container>
    );
};

export default Failure;
