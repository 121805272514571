import React from 'react';

const About = () => {
    return (
        <h1>
            Это приложение создано в качестве обуч. ролика на ULBI TV
        </h1>
    );
};

export default About;
