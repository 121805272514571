
import React, {useEffect, useState} from 'react';
import './App.css';
import {BrowserRouter} from "react-router-dom";
import Navbar from "./components/UI/Navbar/Navbar";
import AppRouter from "./components/AppRouter";
import {AuthContext} from "./context";
import {gs_token, gs_tokenbilet,gs_selectproduct} from './API/global.js'




function App() {
  const [isAuth, setIsAuth] = useState(false);
  const [isLoading, setLoading] = useState(true);

    gs_selectproduct=1;  //Выбор продукта по умолчанию

  useEffect(() => {
    if (localStorage.getItem('auth')) {
      setIsAuth(true)
    }
    setLoading(false);
  }, [])

  return (
/*<div className="App">
    <button onClick={window['procEpay']}>alert</button>
</div>*/
      <AuthContext.Provider value={{
        isAuth,
        setIsAuth,
        isLoading
      }}>
        <BrowserRouter>
            <Navbar/>
          <AppRouter/>
        </BrowserRouter>
      </AuthContext.Provider>
  )
}

export default App;
