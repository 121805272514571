import axios from "axios";
import {gs_email, gs_root, gs_token, gs_tokenadmin, gs_tokenbilet, gs_idpay} from "./global";



export default class PostService {



    static async getAll(token, limit = 10, page = 1) {
        const response = await axios.get('/api/pays/', {
            params: {
                token: token,
                _limit: limit,
                _page: page
            }
        })
        return response;
    }

    static async getStatus(id) {
        const response = await axios.get('/api/invoice/', {
            params: {
                id: id,
            }
        })
        return response;
    }

    static async getById(id) {
        const response = await axios.get('https://jsonplaceholder.typicode.com/posts/' + id)
        return response;
    }

    static async getCommentsByPostId(id) {
        const response = await axios.get(`https://jsonplaceholder.typicode.com/posts/${id}/comments`)
        return response;
    }

    static async sentEmail(email, proc, proc2) {
        const userToPost = {
            email: email
        };
     //   console.log("RepeatClick3");
        const response = await axios
            .post('/api/sentemail/', userToPost)
            .catch((error) => console.log('Error: ', error));
        //   console.log(response);
        if (response && response.data) {
      //      console.log(response);
            if (response.data.success) {
     //           console.log("RepeatClick5");
      //          console.log('token ' + response.data.token);

                gs_token = response.data.token;
                proc();
            }
        } else {
       //     console.log("RepeatClick4");
            proc2();

        }
    }

    static async loginAdmin(email, password, proc, proc2) {
        const userToPost = {
            email: email,
            password: password
        };
        //   console.log("RepeatClick3");
        const response = await axios
            .post('/api/login/', userToPost)
            .catch((error) => console.log('Error: ', error));
        //   console.log(response);
        if (response && response.data) {
            //      console.log(response);
            if (response.data.success) {
                //           console.log("RepeatClick5");
                //          console.log('token ' + response.data.token);

                global.gs_tokenadmin = response.data.token;
                gs_tokenadmin=response.data.token
                proc();
            }
        } else {
            //     console.log("RepeatClick4");
            proc2();

        }
    }

    //Список продуктов
    static async getProducts(token) {
        const response = await axios.get('/api/products/', {
            params: {
                token:token
            }
        })
        return response;
    }

    //Отправка платежа
    static async sentPay(email, gs_tokenbilet, id, name, description, price, count, summa, procSentPay) {
        const userToPost = {
            email: email,
            token: gs_tokenbilet,
            id: id,
            name: name,
            description: description,
            price: price,
            count: count,
            summa: summa,
        };
        const response = await axios
            .post('/api/sentpay/', userToPost)
            .catch((error) => console.log('Error: ', error));

        if (response && response.data) {
            //console.log('aaa '+response.data);
            if (response.data.success) {
                procSentPay(response.data);  //вызов функции для перехода на страницу оплаты

                return true;
            }
        } else {

        }
        return false;
    }

}
