import React from 'react';
import classes from './MyInput.module.css';
import {Container, Form, Spinner} from "react-bootstrap";

const MyPasswordInput = React.forwardRef((props, ref) => {
    return (
        <Form.Control  type="text" ref={ref} className={classes.myPasswordInput} {...props}/>
    );
});

export default MyPasswordInput;
