import React, {useContext} from 'react';
import {useNavigate, Routes, Route} from "react-router-dom";
import {AuthContext} from "../context";
import Loader from "./UI/Loader/Loader";
import Posts from "../pages/musor/Posts";
import About from "../pages/musor/About";
import PostIdPage from "../pages/musor/PostIdPage";
import Login from "../pages/Login";
import Password from "../pages/Password";
import IndexMain from "../pages/IndexMain";
import Products from "../pages/musor/Products";
import {gs_token, gs_tokenbilet, gs_selectproduct} from '../API/global.js'
import Product from "../pages/Product";
import Success from "../pages/Success";
import Failure from "../pages/Failure";
import Admin from "../pages/Admin";


const AppRouter = () => {
    const {isAuth, isLoading} = useContext(AuthContext);
    //  console.log(isAuth)
    let navigate = useNavigate();
    if (isLoading) {
        return <Loader/>
    }

    const reload = () => window.location.reload();
    return (
        (global.gs_tokenadmin)
            ?
            <Routes>
                <Route path="/admin" element={<Admin/>}/>
                <Route path="/login" element={<Login/>} exact={true}/>
            </Routes>
            :
            (gs_token)
                ?
                (gs_tokenbilet)
                    ?
                    <Routes>
                        <Route path="/product" element={<Product/>}/>
                        <Route path="/posts" element={<Posts/>}/>
                        <Route path="/about" element={<About/>}/>
                        <Route path="/posts/:id" element={<PostIdPage/>} exact={true}/>
                        <Route path="/login" element={<Login/>} exact={true}/>
                        <Route path="/password" element={<Password/>} exact={true}/>
                        <Route path="/payment-api.js" onEnter={reload}/>
                        <Route path="/success" element={<Success/>}/>
                        <Route path="/failure" element={<Failure/>}/>
                        <Route path="/" element={<IndexMain/>}/>
                    </Routes>
                    :
                    <Routes>
                        <Route path="/password" element={<Password/>} exact={true}/>
                        <Route path="/" element={<IndexMain/>}/>
                        <Route path="/payment-api.js" onEnter={reload}/>
                        <Route path="/success" element={<Success/>}/>
                        <Route path="/failure" element={<Failure/>}/>
                        <Route path="/login" element={<Login/>} exact={true}/>
                        <Route path="*" element={<IndexMain/>}/>
                    </Routes>
                :
                <Routes>
                    <Route path="/" element={<IndexMain/>}/>
                    <Route path="/payment-api.js" onEnter={reload}/>
                    <Route path="/success" element={<Success/>}/>
                    <Route path="/failure" element={<Failure/>}/>
                    <Route path="/login" element={<Login/>} exact={true}/>
                    <Route path="*" element={<IndexMain/>}/>
                </Routes>

        // navigate("/posts")
    );
};

export default AppRouter;
