import React, {useContext, useRef, useState} from 'react';
import MyInput from "../components/UI/input/MyInput";
import MyButton from "../components/UI/button/MyButton";
import {AuthContext} from "../context";
import {useNavigate} from "react-router-dom";
import MyPasswordInput from "../components/UI/input/MyPasswordInput";
import axios from "axios";
import PostService from "../API/PostService";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Col, Container, Form, Row, Spinner} from "react-bootstrap";
import CloseButton from "react-bootstrap/CloseButton";
import {gs_email, gs_root, gs_tokenbilet} from '../API/global.js'
import {gs_token} from "../API/global";
import Footer from "../components/Footer";

const Password = () => {
    const token = useRef();
    const tokenpin = useRef();
    const email = useRef();
    const [message, setMessage] = useState("");
    const {isAuth, setIsAuth} = useContext(AuthContext);
    const [value1, setValue1] = useState('')
    const [value2, setValue2] = useState('')
    const [value3, setValue3] = useState('')
    const [value4, setValue4] = useState('')
    const [seconds, setSeconds] = useState(60);
    const [timerActive, setTimerActive] = React.useState(true);
    const {passdata, setPassdata} = useContext(AuthContext);
    const [repeat, setRepeat] = useState(false)
    const [repeatDisabled, setRepeatDisabled] = useState(false)
    const [okDisabled, setOkDisabled] = useState(true)
    const [loading, setLoading] = useState(false);
    const [loadingEmail, setLoadingEmail] = useState(false);

    const item1 = useRef();
    const item2 = useRef();
    const item3 = useRef();
    const item4 = useRef();
    const sendButton = useRef();
    let navigate = useNavigate();

    function checkNumbers() {
        if (!((value1>='0') && (value1<='9') && (value2>='0') && (value2<='9') && (value3>='0') && (value3<='9') && (value4>='0') && (value4<='9'))) {
            setOkDisabled(true);

            return false;
        }
        setOkDisabled(false);

        return true;
    }

    const password = async (event) => {
        event.preventDefault();
   //    console.log('1111111111');

        if (!checkNumbers()) {
            return;
        }
        setTimerActive(!timerActive);
        let pin = value1 + value2 + value3 + value4;

        const userToPost = {
            email: gs_email,
            pin: pin,
            token: gs_token,
        };
        let flag = false;
        console.log(userToPost);
        setLoading(true);
        setOkDisabled(true);
        const response = await axios
            .post('/api/checkpin/', userToPost)
            .catch((error) => {
                console.log('Error: ', error)
                setOkDisabled(false);
                setLoading(false);

            });
        //   console.log(response);
        if (response && response.data) {
            console.log(response);
            if (response.data.success) {
                setLoading(false);

                gs_tokenbilet = response.data.token;
                global.gs_tokenbilet = response.data.token;
                localStorage.setItem('step', '2');
                navigate("/product")
            } else {
                setOkDisabled(false);
                setLoading(false);
                setMessage('Не верный пин!');
                setTimerActive(false);

            }
        } else {
            setOkDisabled(false);
            setLoading(false);

        }

        if (flag) {

        }
    }


    React.useEffect(() => {
        if (seconds > 0 && timerActive) {
            setTimeout(setSeconds, 1000, seconds - 1);
 //           setTimeout(setSeconds, 100, seconds - 1);
        } else {
            setTimerActive(false);
            setRepeatDisabled(false);
            setOkDisabled(true);
            setRepeat(true);
            setSeconds(0);
            console.log("Timer end");
        }
    }, [seconds, timerActive]);

    const backClick = (e) => {
        e.preventDefault();
        navigate("/")
    }

    function proc() {
        setLoadingEmail(false);
        setValue1('');
        setValue2('');
        setValue3('');
        setValue4('');
        item1.current.focus();
        setOkDisabled(false);
        setSeconds(60);
        setRepeatDisabled(false);
        setTimerActive(true);
    }

    function proc2() {
        setOkDisabled(false);
        setLoadingEmail(false);
    }

    const repeatClick = (e) => {
        e.preventDefault();
        setMessage('');
      //  console.log("RepeatClick");
        setRepeatDisabled(true);
        setOkDisabled(true);
        setLoadingEmail(true);
        // setOkDisabled(true);
      //  console.log("RepeatClick2");
        PostService.sentEmail(gs_email, proc, proc2);
        setRepeat(false);
    }

    const procValue1 = event => {
        event.preventDefault();
        let last = event.target.value;
        last = last.substr(-1);
        if ((last >= '0') && (last <= '9')) {
     //       console.log('111');
            setValue1(last);
            if (!((last>='0') && (last<='9') && (value2>='0') && (value2<='9') && (value3>='0') && (value3<='9') && (value4>='0') && (value4<='9'))) {
                setOkDisabled(true);

            } else {
                setOkDisabled(false);
            }

            item2.current.focus();
        } else {
            event.target.value = '';
            setOkDisabled(true);
  //          console.log('222');
        }

    }

    const procValue2 = event => {
        event.preventDefault();
        let last = event.target.value;
        last = last.substr(-1);
        if ((last >= '0') && (last <= '9')) {
            setValue2(last);
            if (!((last>='0') && (last<='9') && (value1>='0') && (value1<='9') && (value3>='0') && (value3<='9') && (value4>='0') && (value4<='9'))) {
                setOkDisabled(true);

            } else {
                setOkDisabled(false);
            }
            item3.current.focus();
        } else {
            event.target.value = '';
            setOkDisabled(true);
        }

    }

    const procValue3 = event => {
        event.preventDefault();
        let last = event.target.value;
        last = last.substr(-1);
        if ((last >= '0') && (last <= '9')) {
            setValue3(last);
            if (!((last>='0') && (last<='9') && (value1>='0') && (value1<='9') && (value2>='0') && (value2<='9') && (value4>='0') && (value4<='9'))) {
                setOkDisabled(true);

            } else {
                setOkDisabled(false);
            }
            item4.current.focus();
        } else {
            event.target.value = '';
            setOkDisabled(true);
        }
    }

    const procValue4 = event => {
        event.preventDefault();
        let last = event.target.value;
        last = last.substr(-1);
        if ((last >= '0') && (last <= '9')) {
            setValue4(last);
            if (!((last>='0') && (last<='9') && (value1>='0') && (value1<='9') && (value2>='0') && (value2<='9') && (value3>='0') && (value3<='9'))) {
                setOkDisabled(true);

            } else {
                setOkDisabled(false);
            }
        } else {
            event.target.value = '';
            setOkDisabled(true);
        }
    }

    const valueFocus = event => {
        event.preventDefault();

        event.target.select();
    }

    const procKeyUp = event => {
        if (event.charCode === 13) {
            password(event);
        }
    }

    return (
        <Container>


            <form onSubmit={password}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Text className="mb-3">
                        <h3>
                            Введите код, отправленный на почту
                        </h3>
                        <h4>{gs_email}</h4>
                    </Form.Text>

                    <Form.Text className="mb-3">
                        Введите pin
                    </Form.Text>
                    <Row className="mb-3" style={{width: '240px'}}>
                        <Form.Group as={Col} controlId="formPin1">
                            <MyPasswordInput autoFocus ref={item1} value={value1} onChange={procValue1}
                                             type="text" onFocus={valueFocus} onKeyPress={procKeyUp}
                                             placeholder=""/>
                        </Form.Group>

                        <Form.Group as={Col} controlId="formPin2">
                            <MyPasswordInput value={value2} ref={item2} onFocus={valueFocus}
                                             onChange={procValue2} onKeyPress={procKeyUp}
                                             type="text"
                                             placeholder=""/>
                        </Form.Group>

                        <Form.Group as={Col} controlId="formPin3">
                            <MyPasswordInput value={value3} ref={item3} onFocus={valueFocus}
                                             onChange={procValue3} onKeyPress={procKeyUp}
                                             type="text"
                                             placeholder=""/>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formPin4">
                            <MyPasswordInput value={value4} ref={item4} onFocus={valueFocus}
                                             onChange={procValue4} onKeyPress={procKeyUp}
                                             type="text"
                                             placeholder=""/>
                        </Form.Group>
                    </Row>


                    <Row className="mb-3">
                        <Form.Text className="mb-2" style=
                            {{
                                 color : "red"
                            }}>
                        {message}
                        </Form.Text>
                        <Form.Text className="mb-2">
                            Получить новый код можно через{' '}
                            {seconds} сек

                        </Form.Text>


                    </Row>


                </Form.Group>
                <div className='mt-1'>
                    <MyButton onClick={backClick}>Назад</MyButton>{' '}
                    <MyButton onClick={password} disabled={okDisabled}>
                        {
                            loading
                                ?
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                : <span>Далее</span>
                        }

                    </MyButton>
                </div>
                {repeat ? <div className='mt-1'>
                    <MyButton disabled={repeatDisabled} onClick={repeatClick}>
                        {
                            loadingEmail
                                ? <span>
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />{' '}Отправить код повторно</span>
                                : <span>Отправить код повторно</span>
                        }
                    </MyButton>
                </div> : null}

            </form>
            <Footer/>
        </Container>
    );
};

export default Password;
