import React from 'react';
import {Container} from "react-bootstrap";
import Footer from "../components/Footer";

const Success = () => {
    return (
        <Container>
            <div>
                <h1>
                    Операция прошла успешно
                </h1>
                <span>Уведомление об оплате товара и программное обеспечение будет отправлено по указанной вами электронной почты</span>
            </div>
            <Footer/>
        </Container>
    );
};

export default Success;
