import React, {useState} from 'react';

const Footer = function () {

    return (
        <div>
            <div className="payment"></div>

            <div className="row">

                <ul className="list-inline-block">
                    <li>
                        <h4>Мы принимаем</h4>
                    </li>
                    <li>
                        <div className="payment-method">
                            <a href="src/components/Footer"  className="wobble-top"
                               id="bx_1373509569_50">
                                <img src="/static/epay.png" alt="Epay"></img>
                            </a>
                            <a href="src/components/Footer#"  className="wobble-top" id="bx_1373509569_44">
                                <img src="/static/visa.png" alt="Visa"></img>
                            </a>
                            <a href="src/components/Footer#"  className="wobble-top" id="bx_1373509569_46">
                                <img src="/static/visa2.png" alt="Visa-e"></img>
                            </a>
                            <a href="src/components/Footer#"  className="wobble-top" id="bx_1373509569_45">
                                <img src="/static/mastercard.png" alt="MasterCard"></img>
                            </a>
                            <a href="src/components/Footer#"  className="wobble-top" id="bx_1373509569_47">
                                <img src="/static/maestro.png" alt="Maestro"></img>
                            </a>
                        </div>
                    </li>

                </ul>
            </div>
        </div>
    )
}

export default Footer;
