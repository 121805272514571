// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MyModal_myModal__FEBrT {\n    position: fixed;\n    top: 0;\n    bottom: 0;\n    right: 0;\n    left: 0;\n    display: none;\n    background: rgba(0,0,0, 0.5);\n}\n\n.MyModal_myModalContent__oA8Eb {\n    padding: 25px;\n    background: white;\n    border-radius: 16px;\n    min-width: 250px;\n}\n\n.MyModal_myModal__FEBrT.MyModal_active__fuRwM {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n", "",{"version":3,"sources":["webpack://./src/components/UI/MyModal/MyModal.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,SAAS;IACT,QAAQ;IACR,OAAO;IACP,aAAa;IACb,4BAA4B;AAChC;;AAEA;IACI,aAAa;IACb,iBAAiB;IACjB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":[".myModal {\n    position: fixed;\n    top: 0;\n    bottom: 0;\n    right: 0;\n    left: 0;\n    display: none;\n    background: rgba(0,0,0, 0.5);\n}\n\n.myModalContent {\n    padding: 25px;\n    background: white;\n    border-radius: 16px;\n    min-width: 250px;\n}\n\n.myModal.active {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"myModal": "MyModal_myModal__FEBrT",
	"myModalContent": "MyModal_myModalContent__oA8Eb",
	"active": "MyModal_active__fuRwM"
};
export default ___CSS_LOADER_EXPORT___;
