import React, {useContext, useRef, useState} from 'react';
import MyInput from "../components/UI/input/MyInput";
import MyButton from "../components/UI/button/MyButton";
import Loader from "../components/UI/Loader/Loader";
import {AuthContext, TokenContext} from "../context";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useFetching} from "../hooks/useFetching";
import PostService from "../API/PostService";
import axios from "axios";
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Container, Form, Spinner} from "react-bootstrap";
import {gs_email, gs_token, gs_selectproduct} from '../API/global.js'
import validator from "validator";
import Navbar from "../components/UI/Navbar/Navbar";
import Footer from "../components/Footer";

const IndexMain = () => {
        const token = useRef();
        const email = useRef();
        const [validated, setValidated] = useState(false);
        const [state, setState] = useState({email: ''});
        //   const {isAuth, setIsAuth} = useContext(AuthContext);
        const [message, setMessage] = useState("");
        // const {emaildata, setEmaildata} = useContext(AuthContext);
        let navigate = useNavigate();
        const [okDisabled, setOkDisabled] = useState(false)
        const [loading, setLoading] = useState(false);

        const [searchParams] = useSearchParams();
        var product_id = searchParams.get('product');
        //     const query = new URLSearchParams(this.props.location.search);
        //      query.get('product')
        //      console.log(product_id)//123


        if (product_id) {
            //       console.log('gs_selectproduct ');
            gs_selectproduct = product_id;
            global.gs_selectproduct = product_id;
            //       console.log('gs_selectproduct '+global.gs_selectproduct);
        }

        const login = event => {
            event.preventDefault();
        }


        function proc() {
            setLoading(false);
            //     token.current = response.data.token;
            //    console.log('token ' + token.current);
            gs_email = state.email;
            global.gs_email = state.email;
            //      gs_token = response.data.token;
            localStorage.setItem('step', '1');
            navigate("/password")
        }

        function proc2() {
            setOkDisabled(false);
            setLoading(false);
        }

        const loginClick = async (event) => {

            const form = event.currentTarget;
            if (form.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();

            } else {

            }
            if (validator.isEmail(state.email)) {


            } else {
                setValidated(true);
                return;
            }
            setLoading(true);
            setOkDisabled(true);
            PostService.sentEmail(state.email, proc, proc2);

            /*    const userToPost = {
                    email: state.email
                };

                const response = await axios
                    .post('/api/sentemail/', userToPost)
                    .catch((error) => console.log('Error: ', error));
                //   console.log(response);
                if (response && response.data) {
                        console.log(response);
                    if (response.data.success) {
                        setLoading(false);
                        token.current = response.data.token;
                        console.log('token ' + token.current);
                        gs_email = state.email;
                        gs_token = response.data.token;
                        localStorage.setItem('step', '1');
                        navigate("/password")
                    }
                } else {
                    setOkDisabled(false);
                    setLoading(false);
                }
    */
            /*
            axios
                .get(`/api/sentemail/`, {
                    email: state.email,

                }, {
                    headers: {
                        "Access-Control-Allow-Origin": '*',
                        "Access-Control-Allow-Methods": 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                        "Content-Type": "application/x-www-form-urlencoded"
                    }
                })
                .then((response) => {

                    console.log(response.data);
                    if (response.data.success) {

                        token.current = response.data.token;
                        localStorage.setItem('step', '1')
                        navigate("/password")
                    }
                });*/

        }


        function handleChange(event) {
            console.log(event.target.value);
            let a = event.target.value;
            setState({email: a});
        }


        return (
            <Container>
                <Form noValidate validated={validated} onSubmit={login}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Text className="mb-3">
                            <h3>
                                Введите данные для покупки программного обеспечения
                            </h3>
                        </Form.Text>
                        <Form.Label>Email</Form.Label>
                        <Form.Control required type="email" placeholder="Введите вашу почту" value={state.email}
                                      onChange={handleChange}/>
                        <Form.Control.Feedback type="invalid">
                            Пожалуйста, введите корректный адрес электронной почты
                        </Form.Control.Feedback>
                        <br/>
                        <span
                            style={{
                                fontWeight: "bold",
                                color: "red"
                            }}
                        >
        {message}
      </span>
                        <Form.Text className="text-muted">
                            Вам будет отправлен код по E-mail
                        </Form.Text>
                    </Form.Group>

                    <MyButton onClick={loginClick} variant="primary" disabled={okDisabled} type="submit">
                        {
                            loading
                                ?
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                : <span>Далее</span>
                        }
                    </MyButton>

                </Form>

                <Footer/>
            </Container>
            /* <div>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" placeholder="Password" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" label="Check me out" />
                    </Form.Group>

                    <h1>Введите данные для получения билета</h1>
                    <form onSubmit={login}>
                        <div>
                            <span>E-mail</span>
                            <MyInput value={state.email} onChange={handleChange} type="text"
                                     placeholder="Введите вашу почту"/>
                            <span>Вам будет отправлен код по E-mail</span>
                        </div>
                        <MyButton disabled={okDisabled}>Далее</MyButton>
                    </form>
                </div>*/
        );
    }
;

export default IndexMain;
