// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MyButton_myBtn__ouLSk {\n    padding: 5px 15px;\n    color: teal;\n    font-size: 14px;\n    background: transparent;\n    border: 1px solid teal;\n    cursor: pointer;\n}\n\n.MyButton_myPasswordBtn__gN\\+Vm {\n    padding: 5px 15px;\n    color: teal;\n    font-size: 14px;\n    background: transparent;\n    border: 1px solid teal;\n    cursor: pointer;\n}", "",{"version":3,"sources":["webpack://./src/components/UI/button/MyButton.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,WAAW;IACX,eAAe;IACf,uBAAuB;IACvB,sBAAsB;IACtB,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,WAAW;IACX,eAAe;IACf,uBAAuB;IACvB,sBAAsB;IACtB,eAAe;AACnB","sourcesContent":[".myBtn {\n    padding: 5px 15px;\n    color: teal;\n    font-size: 14px;\n    background: transparent;\n    border: 1px solid teal;\n    cursor: pointer;\n}\n\n.myPasswordBtn {\n    padding: 5px 15px;\n    color: teal;\n    font-size: 14px;\n    background: transparent;\n    border: 1px solid teal;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"myBtn": "MyButton_myBtn__ouLSk",
	"myPasswordBtn": "MyButton_myPasswordBtn__gN+Vm"
};
export default ___CSS_LOADER_EXPORT___;
